<template>
    <Layout color="white">
        <div class="perfil-container">
            <v-container class="perfil-container01">
                <v-icon @click="$axios.rota('Home')" class="fechar" v-if="$config.mobile() || $config.responsive() < 991">mdi-close mdi-color-black</v-icon>
                <label class="perfil-text">{{$t_('cadastro')}}</label>
                <h2 class="perfil-text01">{{$t_('perfil_usuario')}}</h2>
                <v-row justify="start" align="center" class="my-3">

                    <v-col cols="12" md="4" v-if="cadastro.idpessoa_tipo != $config.regrasDoNegocio.idagencia">
                        <label required>{{ $t_('nome') }}</label>
                        <v-text-field v-model="cadastro.nome" type="text" dense outlined
                            hide-details tabindex="1" class="mt-3"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4" v-if="cadastro.idpessoa_tipo != $config.regrasDoNegocio.idagencia">
                        <label :required="!cadastro.passaporte">{{ $t_('CPF') }}</label>
                        <v-text-field ref="cpf" v-model="cadastro.cpf" @input="cadastro.passaporte = null" type="tel" dense outlined
                            hide-details class="mt-3" tabindex="2" v-mask="'###.###.###-##'" pattern="[0-9]{11}"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4" v-if="cadastro.idpessoa_tipo != $config.regrasDoNegocio.idagencia">
                        <label :required="!cadastro.cpf">{{$t_('passaporte')}}</label>
                        <v-text-field v-model="cadastro.passaporte" @input="cadastro.cpf = null" type="text" dense outlined
                            hide-details class="mt-3" tabindex="3"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4" v-if="cadastro.idpessoa_tipo == $config.regrasDoNegocio.idagencia">
                        <label required>{{ 'Razão social' }}</label>
                        <v-text-field v-model="cadastro.empresa_razao" type="text"
                            dense outlined hide-details tabindex="4" class="mt-3"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4" v-if="cadastro.idpessoa_tipo == $config.regrasDoNegocio.idagencia">
                        <label required>CNPJ</label>
                        <v-text-field ref="cnpj" v-model="cadastro.empresa_cnpj" type="tel" dense outlined hide-details class="mt-3" tabindex="5"
                            v-mask="'##.###.###/####-##'"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4" v-if="cadastro.idpessoa_tipo == $config.regrasDoNegocio.idagencia">
                        <label required>{{ 'Nome Fantasia' }}</label>
                        <v-text-field v-model="cadastro.empresa_fantasia" type="text"
                            dense outlined hide-details  class="mt-3" ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4"
                        v-if="cadastro.idpessoa_tipo != $config.regrasDoNegocio.idagencia">
                        <label required>{{ $t_('data_nascimento') }}</label>
                       <v-text-field class="mt-3" v-model="cadastro.dt_nascimento" dense outlined 
                                    type="tel" tabindex="6" v-mask="['XX/XX/XXXX']"
                                    :placeholder="placeholderData" hide-details
                                    prepend-icon="mdi-calendar"></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                        <label>{{ $t_('email') }}</label>
                        <v-text-field v-model="cadastro.email" type="text" dense outlined hide-details class="mt-3"
                            disabled style="background-color: #ebebeb" tabindex="7"></v-text-field>
                    </v-col>


                    <v-col cols="12" md="4" v-if="cadastro.idpessoa_tipo != $config.regrasDoNegocio.idagencia">
                        <label required>{{ $t_('celular') }}</label>
                        <VuePhoneNumberInput        
                            v-model="cadastro.celular"
                            type="text"
                            dense
                            outlined
                            hide-details
                            class="mt-3"
                            tabindex="10"
                            :no-use-browser-locale="false"
                            :error="errorNumber"
                            @update="updatePhoneNumber"
                            :translations="phoneInputLang"
                            :preferred-countries="['BR', 'US', 'ES']"
                        ></VuePhoneNumberInput>
                    </v-col>

                    <v-col cols="12" sm="4" class="py-0" v-if="cadastro.idpessoa_tipo == $config.regrasDoNegocio.idagencia">
                        <label required>{{ $t_('TELEFONE') }}</label>
                        <VuePhoneNumberInput        
                            v-model="cadastro.telefone"
                            type="text"
                            dense
                            outlined
                            hide-details
                            key="telefone"
                            class="mt-3"
                            tabindex="10"
                            :no-use-browser-locale="false"
                            :error="errorNumber"
                            @update="updateCellPhoneNumber"
                            :translations="phoneInputLang"
                            :default-country-code="$lang_.getLang().telefone"
                            :preferred-countries="['BR', 'US', 'ES']"
                        ></VuePhoneNumberInput>
                    </v-col>

                </v-row>

                <template v-if="$config.dados.editarEndereco">

                    <v-divider class="mb-6"></v-divider>
                    
                    <h2 class="perfil-text01 mb-5">{{$t_('endereco')}}</h2>

                    <v-row>
                        <v-col cols="12" md="4">
                            <label required>{{$t_('cep')}}</label>
                            <v-text-field v-model="cadastro.end_cep" type="tel" dense outlined hide-details class="mt-3" tabindex="9" v-mask="'#####-###'"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <label required>{{$t_('pais')}}</label>
                            <v-text-field v-model="cadastro.end_pais" type="text" dense outlined hide-details class="mt-3" tabindex="9"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <label required>{{$t_('estado')}}</label>
                            <v-text-field v-model="cadastro.end_estado" type="text" dense outlined hide-details class="mt-3" tabindex="9"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <label required>{{$t_('cidade')}}</label>
                            <v-text-field v-model="cadastro.end_cidade" type="text" dense outlined hide-details class="mt-3" tabindex="9"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <label required>{{$t_('bairro')}}</label>
                            <v-text-field v-model="cadastro.end_bairro" type="text" dense outlined hide-details class="mt-3" tabindex="13"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                            <label required>{{$t_('numero')}}</label>
                            <v-text-field v-model="cadastro.end_numero" v-mask="'########'" type="tel" dense outlined hide-details class="mt-3" tabindex="14"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                            <label required>{{$t_('logradouro')}}</label>
                            <v-text-field v-model="cadastro.end_logradouro" type="text" dense outlined hide-details class="mt-3" tabindex="15"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4">
                            <label>{{$t_('complemento')}}</label>
                            <v-text-field v-model="cadastro.end_complemento" type="text" dense outlined hide-details class="mt-3" tabindex="16"></v-text-field>
                        </v-col>

                    </v-row>
                </template>

                <v-row>

                    <v-col cols="12" md="4">
                        <span class="red--text"> * {{ $t_('campos_obrigatorios') }}</span><br>
                        <span>{{ $t_('preencha') }}</span>
                    </v-col>

                </v-row>

                <v-row justify="center" align="center" class="mt-5">

                    <v-col cols="12" md="12" class="d-flex justify-center align-center">
                        <v-checkbox v-model="cadastro.termos" class="ma-0" tabindex="17">
                            <template v-slot:label>
                                <div>
                                    {{ $t_('aceito_termos_1') }}
                                    <a target='_blank' style="text-decoration:underline"
                                        @click.stop="showPoliticas = true"> {{ $t_('politica_privacidade') }}</a>
                                </div>
                            </template>
                        </v-checkbox>
                    </v-col>

                </v-row>

                <v-row justify="center" align="center" class="mt-2">

                    <v-col cols="12">
                        <v-row justify="center" align="center">
                            <v-col cols="12" md="3">
                                <v-btn elevation="0" :color="$config.dados.corprimaria" style="color: white" large block
                                    @click="response()" tabindex="18">
                                    <strong v-once v-html="$t_('salvar')"></strong>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                </v-row>

            </v-container>
        </div>
        <ModalTermos :showTermosSite="showTermosSite" @update-dialog="updateShowTermos" />
        <ModalPoliticas :showPoliticas="showPoliticas" @update-dialog="updateShowPoliticas" />


    </Layout>
</template>

<script>
    import { mask } from "vue-the-mask";
    import VuePhoneNumberInput from 'vue-phone-number-input';
    export default {
        name: 'Perfil',
        components: {
            VuePhoneNumberInput,
            Layout: () => import('@/layout/Default.vue'),
            ModalTermos: () => import('@/components/modal-termos.vue'),
            ModalPoliticas: () => import('@/components/modal-politicas.vue'),
        },
        directives: { mask },
        data() {
            return {
                cadastro: [],
                // cadastro: {
                //     end_cep:'',
                //     end_pais:'',
                //     end_estado:'',
                //     end_cidade:'',
                //     end_bairro:'',
                //     end_logradouro:'',
                // },
                showDialog: false,
                showDialogConfirm: false,
                terms: false,
                showTermosSite: false,
                showPoliticas: false,
                placeholderData: 'dd/mm/aaaa',
                menu: false,
                activePicker: null,
                pais: [],
                estados: [],
                phoneInputLang: [],
                errorNumber: false,
                carregandoDadosCep: false
            }
        },
        watch: {
            menu(val) {
                val && setTimeout(() => (this.activePicker = 'YEAR'))
            },
            async 'cadastro.end_cep'(cep){
                this.cadastro.end_cep_loading = false
                if(this.cadastro.end_cep && this.cadastro.end_cep.length == 8 ){
                    return this.$util.getCEP(cep)
                }
                else if(this.$usuario.cadastro && !this.$usuario.cadastro.end_cep){
                    this.$usuario.cadastro.end_pais       = ""
                    this.$usuario.cadastro.end_estado     = ""
                    this.$usuario.cadastro.end_cidade     = ""
                    this.$usuario.cadastro.end_bairro     = ""
                    this.$usuario.cadastro.end_logradouro = ""
                    this.cadastro.end_cep_loading = true
                    this.$usuario.cadastro.end_cep = await this.$util.getCEP(this.cadastro.end_cep)
                    this.cadastro.end_cep_loading = false
                }

            },
            '$util.responseCEP'(e){
                this.cadastro.end_cep_loading = false
                if(!this.carregandoDadosCep){
                    this.cadastro.end_pais       = 'Brasil'
                    this.cadastro.end_estado     = e.uf
                    this.cadastro.end_cidade     = e.localidade
                    this.cadastro.end_bairro     = e.bairro 
                    this.cadastro.end_logradouro = e.logradouro
                }
            },
        },
        methods: {

            updatePhoneNumber(data) {
                this.cadastro.celular = data.formattedNumber;
                this.cadastro.celularComCodigoPais = data.e164;
            },
            updateCellPhoneNumber(data) {
                const mask = this.$util.celmask.find(item => item.code == data.countryCallingCode)
                
                if(data.nationalNumber){
                    setTimeout(() => {
                        const celular = data.nationalNumber.toString().substring(0, mask.mask);
                              document.getElementsByClassName('input-tel__input')[0].value = celular;
                        this.cadastro.telefone              = celular
                        this.cadastro.telefoneComCodigoPais = data.e164;
                    }, 10)
                }
            },
            traducoesNumber(){
                let getPhoneNumber = this.$lang_.getLang()

                if(getPhoneNumber.lang === 'pt-BR') {
                    this.phoneInputLang = {
                        countrySelectorLabel: 'Código do país',
                        countrySelectorError: 'Escolha um país',
                        phoneNumberLabel: 'Número de telefone',
                        example: 'Exempĺo:'
                    }
                }

                if(getPhoneNumber.lang === 'es-ES') {
                    this.phoneInputLang = {
                        countrySelectorLabel: 'Código do país',
                        countrySelectorError: 'Elige un pais',
                        phoneNumberLabel: 'Número de teléfono',
                        example: 'Ejemplo:'
                    }
                }

                if(getPhoneNumber.lang === 'en-US'){
                    this.placeholderData = 'mm/dd/yyyy'
                }
            },

            search(array, search){
                let pesquisa = this.$util.replaceSpecialChars(search.toString().toLocaleLowerCase().normalize("NFD"));
                let palavra = this.$util.replaceSpecialChars(array.dsc_lang.toString().toLocaleLowerCase().normalize("NFD"));

                if(palavra.indexOf(pesquisa) != -1){
                    return array;
                }
            },
             
            response() {
                
                const temp = Object.assign({}, this.cadastro)

                if(temp.idpessoa_tipo == this.$config.regrasDoNegocio.idagencia){
                    if(!temp.empresa_cnpj && temp.empresa_razao < 2 || temp.empresa_razao == '')
                        { this.$config.alertWarn(this.$t_('preencha_dados')); return }
                    temp.nome = temp.empresa_razao
                } else {

                    if(temp.nome.length < 2 || temp.nome == '')
                        { this.$config.alertWarn(this.$t_('preencha_nome_corret')); return }
                    if(!temp.passaporte && !temp.cpf)
                        { this.$config.alertWarn(this.$t_('preencha_documento')); return }
                    temp.nome = temp.nome
                }

                if(!temp.celular || temp.celular.length < 6 || temp.celular == '')
                    { this.$config.alertWarn(this.$t_('preencha_cel_corret')); return } 

                temp.empresa_fantasia = temp.empresa_razao
                
                this.$usuario.cadastro = temp
                this.$usuario.setEdit(temp);
            },
            updateShowTermos(showDialog) {
                this.showTermosSite = showDialog
            },
            updateShowPoliticas(showDialog) {
                this.showPoliticas = showDialog
            },
            save(date) {
                this.$refs.menu.save(date)
                this.cadastro.dt_nascimento = this.$util.convertData(date)
            },
         
        },
        mounted() {
            const self = this;

            this.traducoesNumber();

            if(!this.$axios.getLocal('session') || !this.$axios.getLocal('token')) {
                this.$axios.remove('session')
                this.$axios.remove('token')
                this.$router.go(-1);
            }

            this.$usuario.getPerfil()
            .then(response => {
                this.carregandoDadosCep = true;

                this.cadastro = response
                this.cadastro.dt_nascimento = this.$util.convertData(this.cadastro.dt_nascimento)
                this.cadastro.cpf = this.$util.formataCPF(this.cadastro.cpf)

                if(this.cadastro.empresa_cnpj){
                    this.cadastro.empresa_cnpj = this.$util.formataCNPJ(this.cadastro.empresa_cnpj)
                }

                this.$axios.setLocal('session', response);
                setTimeout(() => {
                    this.carregandoDadosCep = false;
                },2000)
            })
        },
    }
</script>

<style scoped>
    .perfil-container {
        width: 100%;
        height: auto;
        display: flex;
        padding-bottom: 30px;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        background-color: white;
    }

    .perfil-container01 {
        width: 991px;
        height: fit-content;
        padding: 15px;
        flex-direction: row;
        
    }

    .perfil-container02 {
        flex: 0 0 auto;
        width: 100%;
        display: flex;
        padding: 10px;
        align-items: flex-start;
        margin-bottom: 10px;
        flex-direction: column;
        justify-content: flex-start;
    }

    .perfil-text {
        color: var(--dl-color-gray-500);
        font-size: 12px;
    }

    .perfil-text01 {
        color: var(--dl-color-gray-500);
        font-size: px;
    }

    .perfil-container03 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        padding: 10px;
        position: relative;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }

    .perfil-text02 {
        color: var(--dl-color-gray-black);
        font-size: 14px;
    }

    .perfil-text04 {
        color: #d2232aff;
    }

    .perfil-textinput {
        color: var(--dl-color-gray-black);
        width: 100%;
        height: 35px;
        margin-top: 5px;
        border-color: var(--dl-color-gray-900);
        margin-bottom: 5px;
    }

    .perfil-icon {
        fill: var(--dl-color-success-700);
        right: 17px;
        width: 24px;
        bottom: 21px;
        height: 24px;
        position: absolute;
    }

    .perfil-container04 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        padding: 10px;
        position: relative;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }

    .perfil-text05 {
        color: var(--dl-color-gray-black);
        font-size: 14px;
    }

    .perfil-text07 {
        color: #d2232aff;
    }

    .perfil-textinput1 {
        color: var(--dl-color-gray-black);
        width: 100%;
        height: 35px;
        margin-top: 5px;
        border-color: var(--dl-color-gray-900);
        margin-bottom: 5px;
    }

    .perfil-icon02 {
        fill: var(--dl-color-success-700);
        right: 17px;
        width: 24px;
        bottom: 21px;
        height: 24px;
        position: absolute;
    }

    .perfil-container05 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        padding: 10px;
        position: relative;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }

    .perfil-text08 {
        color: var(--dl-color-gray-black);
        font-size: 14px;
    }

    .perfil-textinput2 {
        color: var(--dl-color-gray-black);
        width: 100%;
        height: 35px;
        margin-top: 5px;
        border-color: var(--dl-color-gray-900);
        margin-bottom: 5px;
    }

    .perfil-icon04 {
        fill: var(--dl-color-success-700);
        right: 17px;
        width: 24px;
        bottom: 21px;
        height: 24px;
        position: absolute;
    }

    .perfil-container06 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        padding: 10px;
        position: relative;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }

    .perfil-text09 {
        color: var(--dl-color-gray-black);
        font-size: 14px;
    }

    .perfil-text11 {
        color: #d2232aff;
    }

    .perfil-textinput3 {
        color: var(--dl-color-gray-black);
        width: 100%;
        height: 35px;
        border-color: var(--dl-color-gray-900);
    }

    .perfil-icon06 {
        fill: var(--dl-color-success-700);
        right: 17px;
        width: 24px;
        bottom: 16px;
        height: 24px;
        position: absolute;
    }

    .perfil-container07 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        padding: 10px;
        position: relative;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }

    .perfil-text12 {
        color: var(--dl-color-gray-black);
        font-size: 14px;
    }

    .perfil-textinput4 {
        color: var(--dl-color-gray-black);
        width: 100%;
        height: 35px;
        margin-top: 5px;
        border-color: var(--dl-color-gray-900);
        margin-bottom: 5px;
    }

    .perfil-icon08 {
        fill: var(--dl-color-success-700);
        right: 17px;
        width: 24px;
        bottom: 21px;
        height: 24px;
        position: absolute;
    }

    .perfil-container08 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        padding: 10px;
        position: relative;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }

    .perfil-text13 {
        color: var(--dl-color-gray-black);
        font-size: 14px;
    }

    .perfil-textinput5 {
        color: var(--dl-color-gray-black);
        width: 100%;
        height: 35px;
        margin-top: 5px;
        border-color: var(--dl-color-gray-900);
        margin-bottom: 5px;
    }

    .perfil-icon10 {
        fill: var(--dl-color-success-700);
        right: 17px;
        width: 24px;
        bottom: 21px;
        height: 24px;
        position: absolute;
    }

    .perfil-container09 {
        width: 33.33%;
        display: flex;
        padding: 10px;
        position: relative;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }

    .perfil-text14 {
        color: var(--dl-color-gray-black);
        font-size: 14px;
    }

    .perfil-textinput6 {
        color: var(--dl-color-gray-black);
        width: 100%;
        height: 35px;
        margin-top: 5px;
        border-color: var(--dl-color-gray-900);
        margin-bottom: 5px;
    }

    .perfil-icon12 {
        fill: var(--dl-color-success-700);
        right: 17px;
        width: 24px;
        bottom: 21px;
        height: 24px;
        position: absolute;
    }

    .perfil-container10 {
        flex: 0 0 auto;
        width: 66.66%;
        display: flex;
        padding: 10px;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }

    .perfil-container11 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        padding: 10px;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }

    .perfil-text15 {
        color: var(--dl-color-danger-500);
        font-size: 16px;
    }

    .perfil-text16 {
        color: var(--dl-color-gray-500);
        font-size: 16px;
    }

    .perfil-container12 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        padding: 10px;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
    }

    .perfil-checkbox {
        color: red;
        transition: 0.3s;
        border-color: #ff0000;
        border-width: 1px;
        margin-right: 15px;
    }

    .perfil-text17 {
        color: var(--dl-color-gray-500);
        font-size: 16px;
    }

    .perfil-container13 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        padding: 10px;
        align-items: center;
        flex-direction: row;
        justify-content: flex-end;
    }

    .perfil-button {
        color: var(--dl-color-gray-white);
        height: 35px;
        font-style: normal;
        margin-top: 0px;
        font-weight: 700;
        border-width: 0px;
        padding-left: 20px;
        padding-right: 20px;
        text-transform: uppercase;
        background-color: #d2232aff;
    }

    @media(max-width: 991px) {
        .perfil-container01 {
            width: 100%;
        }
    }

    @media(max-width: 767px) {
        .perfil-container02 {
            width: 50%;
        }

        .perfil-container03 {
            width: 50%;
        }

        .perfil-container04 {
            width: 50%;
        }

        .perfil-container05 {
            width: 50%;
        }

        .perfil-container06 {
            width: 50%;
        }

        .perfil-container07 {
            width: 50%;
        }

        .perfil-container08 {
            width: 50%;
        }

        .perfil-container09 {
            width: 50%;
        }

        .perfil-container10 {
            width: 100%;
        }

        .perfil-container11 {
            width: 50%;
        }

        .perfil-container12 {
            width: 50%;
        }

        .perfil-container13 {
            width: 50%;
        }

        .perfil-button {
            width: 100%;
            text-align: center;
        }
    }

    @media(max-width: 479px) {
        .perfil-container01 {
            height: auto;
        }

        .perfil-container02 {
            width: 100%;
        }

        .perfil-container03 {
            width: 100%;
        }

        .perfil-container04 {
            width: 100%;
        }

        .perfil-container05 {
            width: 100%;
        }

        .perfil-container06 {
            width: 100%;
        }

        .perfil-container07 {
            width: 100%;
        }

        .perfil-container08 {
            width: 100%;
        }

        .perfil-container09 {
            width: 100%;
        }

        .perfil-container10 {
            width: 100%;
        }

        .perfil-container11 {
            width: 100%;
        }

        .perfil-container12 {
            width: 100%;
        }

        .perfil-container13 {
            width: 100%;
        }

        .perfil-button {
            width: 100%;
            text-align: center;
        }
    }
</style>